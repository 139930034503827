<template>
    <v-row class="service-main-wrapper mt_dec--30">
      <!-- Start Single Service  -->
      <v-col
        lg="10"
        md="6"
        sm="6"
        cols="12"
        v-for="(service, i) in serviceContent"
        :key="i"
      >
        <div class="service service__style--2 text-left bg-gray">
          <div class="service">
            <div class="content">
              <h3 class="colored-title">{{ service.title }}</h3>
              <p class="desc" v-for="description in service.desc" :key="description">
                {{ description }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <!-- End Single Service  -->
    </v-row>
  </template>
  
  <script>
    import feather from "feather-icons";
    export default {
      data() {
        return {
          serviceContent: [
            {
              title: "QUEM SOMOS",
              desc: [
                'Nossa empresa foi fundada por um grupo de sócios experientes em soluções empresariais, que já atuavam no mercado da Síria, Brasil e França, onde localizamos nossa logística.',
                'O nosso propósito consistiu em expandir as nossas aspirações e, portanto, optamos por fundar a Forever Eventos.',
                'Com nossa abordagem inovadora, nossa equipe experiente e nossa plataforma digital avançada, estamos empenhados em oferecer soluções personalizadas para cada cliente.'
              ],
            },
            {
              title: "COMPROMISO COM A DIVERSIDADE",
              desc: [
                'Valorizamos a diversidade em nosso casting e estamos sempre empenhados em promover a inclusão e a representatividade.',
                'Possuímos uma equipe composta por pessoas de diferentes origens, habilidades e identidades de gênero.',
                'A diversidade é fundamental para uma indústria mais inclusiva e estamos prontos para fazer parte dessa mudança.',
              ],
            },
            {
              title: "PLATAFORMA DIGITAL",
              desc: [
                'Em nosso portal, profissionais de todo o Brasil possuem a oportunidade de se cadastrar, compartilhar seu material e detalhar suas experiências passadas, disponibilizando-se para futuras seleções.',
                'Através desse processo transparente e eficiente, buscamos não apenas identificar os profissionais mais capacitados, mas também criar uma conexão valiosa entre a nossa agência e talentos promissores. ',
                'Cada contribuição e experiência única que cada colaborador traz para o ambiente profissional é cuidadosamente valorizada.',
              ],
            },
          ],
        };
      },
  
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
  </script>
  <style>
  .colored-title {
    background: linear-gradient(120deg, #1C99FE 20.69%, #7644FF 50.19%, #FD4766 79.69%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
  }
  .desc {
    font-size: 16px !important;
    margin-bottom: 5px !important;
  }
  </style>
  